import { css } from '@emotion/core';

export const styWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const styWrapperCount = css`
  display: inline-block;
  width: 55px;
  height: 55px;
  background-color: var(--font-primary);
  margin: 8px 8px 16px 8px;
  border-radius: 50%;
  position: relative;
  color: var(--secondary-bg);
  animation: pulse 2s infinite alternate;
  border: 1px solid white;

  h3 {
    color: var(--font-primary);
    font-weight: normal;
    margin: 20px 0 0 0;
    margin-top: 12px;
    margin-bottom: -4px;
    font-size: 16px;
  }

  span {
    font-weight: bold;
    text-transform: none;
    font-size: 10px;
    display: block;
  }
`;

export const styMargin = (px) => css`
  margin: ${px};
`;