import React, { useState } from 'react';
import ReactModal from 'react-modal';
import useCustomBank from '@/hooks/useCustomBank';

ReactModal.setAppElement(`#___gatsby`);

function Modal() {
  const bankType = useCustomBank();
  const [showModal, setShowModal] = useState(false);

  let bankContent = {
    name: 'Mandiri ',
    number: '13500178xxxxx',
    account: 'Deni Ismanto',
  };

  const setToggleModal = () => {
    setShowModal(!showModal);
  };

  // const sentGift = () => {
  //   window.open('http://www.wishsite.net/wishlist/2lbw5t2l');
  // };

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    alert('Rekening berhasil disalin!');
  };

  if (bankType === 'bni') {
    bankContent = {
      name: 'BNI',
      number: '0721934770',
      account: 'Diajeng Imas Gandasari',
    };
  }

  return (
    <div>
      <button
        className="btn btn-secondary btn-sm"
        onClick={setToggleModal}
        style={{ color: 'var(--font-black-ui)', backgroundColor: 'var(--primary-bg)' }}
      >
        <i className="icon-wallet" /> Berikan Angpau
      </button>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.58)',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: '100%',
            zIndex: '999',
            padding: '16px',
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '960px',
            margin: '32px auto',
            padding: '16px',
            borderRadius: '16px',
          },
        }}
        contentLabel="onRequestClose Example"
        onRequestClose={setToggleModal}
      >
        <div className="text-center">
          <h2
            className="main-font color__primary text__title"
            style={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop: '16px' }}
          >
            Berikan Angpau
          </h2>
          <p style={{ padding: '0 16px', color: 'var(--font-black-ui)' }}>
            Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga dapat melakukan
            transfer melalui rekening berikut:
            <br /> <br />
            {/* Bottom */}
            <strong>{`Bank ${bankContent.name} : ${bankContent.number}`} </strong> <br />
            {`a.n. ${bankContent.account}`} <br style={{ marginBottom: '24px' }} />
            <button
              className="btn btn-sm btn-secondary"
              style={{ fontSize: '1.3rem' }}
              onClick={() => copyToClipboard(bankContent.number)}
            >
              Salin Nomor Rekening
            </button>{' '}
          </p>
          <button className="btn btn-primary" onClick={setToggleModal}>
            Kembali
          </button>
        </div>
      </ReactModal>
    </div>
  );
}

export default Modal;
