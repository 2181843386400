import React, { useState, useEffect } from 'react';
import { object, string, bool, func } from 'prop-types';
import { animateScroll } from 'react-scroll';

import { THE_BRIDE, WEDDING_DATE, WEDDING_DOMICILI } from '@/constants';
import WithAnimation from '../WithAnimation/UseIntersect';
import WithAnimationLazy from '../WithAnimation';
import ScrollToDown from './ScrollToDown';
import Countdown from '../Countdown';

import WeddingLogo from '../common/WeddingLogo';

import { styWrapper, styHero, styBackground, styMarginFinal } from './styles';

const DELAY_TIME = 1500;

function WelcomeSection({ location, guestName, isInvitation, isAnonymGuest, codeLink, onClickDetail }) {
  const [loading, setLoading] = useState(false);
  const [alreadyDownloadData, setAlreadyDownloadData] = useState(false);
  const [isHasName, setIsHasName] = useState(false);

  const handleScrollTo = () => {
    /** scroll into detail view */
    const element = document.getElementById('id-hello-section').offsetTop;
    animateScroll.scrollTo(element);
  };

  const handleShowDetail = () => {
    if (loading) return undefined;

    try {
      const myAudio = document.getElementById('myAudio');
      myAudio.play();
    } catch {
      console.error('FAILED_TO_PLAY_MUSIC');
    }

    onClickDetail();

    if (!alreadyDownloadData) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setAlreadyDownloadData(true);
        handleScrollTo();
      }, DELAY_TIME);
    } else {
      handleScrollTo();
    }
  };

  useEffect(() => {
    if (guestName && !isHasName) {
      setIsHasName(true);
    }
  }, [guestName, isHasName]);

  const renderDearest = () => {
    if (!isHasName) return null;

    return (
      <WithAnimation style={{ marginTop: '8em' }}>
        <h3 className="to-dearest">Kepada Bapak / Ibu / Saudara / i, </h3>
        <h2 className="to-dearest-name">{guestName}</h2>
      </WithAnimation>
    );
  };

  return (
    <div css={styHero}>
      <header className="fh5co-cover" css={styBackground}>
        <div className="overlay" />
        <div className="container">
          <div className="row" css={styWrapper(isHasName ? '-12rem 0 0 0' : '-18rem 0 0 0')}>
            <div className="col-md-8 col-md-offset-2 text-center">
              <WithAnimationLazy>
                <div style={{ marginTop: '20px', marginBottom: '-24px' }}>
                  <WeddingLogo />
                </div>
                <h2 className="text__date">The Wedding of</h2>
                <h1 className="text__title">{THE_BRIDE}</h1>
                <h2 className="text__date text__date-bottom">{`${WEDDING_DOMICILI}, ${WEDDING_DATE}`}</h2>
              </WithAnimationLazy>
              <WithAnimation>
                <div css={isHasName ? styMarginFinal(17) : styMarginFinal(20)}>
                  <Countdown />
                </div>
              </WithAnimation>
              {isHasName && renderDearest()}
            </div>
          </div>
          <div className="row">
            <ScrollToDown loading={loading} onClick={handleShowDetail} />
          </div>
        </div>
      </header>
    </div>
  );
}

WelcomeSection.propTypes = {
  guestName: string.isRequired,
  isInvitation: bool.isRequired,
  isAnonymGuest: bool.isRequired,
  location: object.isRequired,
  codeLink: string,
  onClickDetail: func.isRequired,
};

WelcomeSection.defaultProps = {
  codeLink: '',
};

export default WelcomeSection;
