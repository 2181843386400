import React from 'react';

import WithAnimation from '../WithAnimation';
import SectionBox from './SectionBox';

import DATA from './rules-data';
import { styWrapper } from './styles';

function CovidSection() {
  return (
    <div className="content__padding bg__primary" css={styWrapper}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <WithAnimation>
              <h2 className="main-font color__primary text__title" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
                Protokol Covid-19
              </h2>
              <p className="info" style={{ marginBottom: '-16px' }}>
                <i>Keberlangsungan acara ini mengacu pada pedoman protokol kesehatan:</i>
              </p>
            </WithAnimation>
          </div>
        </div>
        {/* BOX INFO */}
        <div className="row">
          <div className="col-md-12">
            <WithAnimation>
              {DATA.map((item) => {
                return <SectionBox key={item.text} text={item.text} icon={item.icon} />;
              })}
            </WithAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CovidSection;
