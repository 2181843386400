import React from 'react';
import WithAnimation from '../WithAnimation';

import Countdown from '../Countdown';
import { THE_BRIDE, WEDDING_AKAD, WEDDING_AKAD_TIME } from '@/constants';
import { styWrapper } from './styles';

function EmbedLive() {
  return (
    <div id="live-wedding-section" css={styWrapper(false)}>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <WithAnimation>
              <h4 className="pr-co" style={{ marginBottom: 0 }}>
                Counting Down to Our Wedding Day
              </h4>
              <h3
                className="pr-co main-font"
                style={{ marginTop: '2rem', fontSize: '3rem' }}
                dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
              />
              <Countdown />
              <p>{`${WEDDING_AKAD} - ${WEDDING_AKAD_TIME}`}</p>
            </WithAnimation>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmbedLive;
