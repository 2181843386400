import React from 'react';
import { bool, object } from 'prop-types';

import { styWave } from './styles';

function Wave({ inverse, style }) {
  return <div css={styWave(inverse)} style={style} />;
}

Wave.propTypes = {
  inverse: bool,
  style: object,
};

Wave.propTypes = {
  inverse: false,
  style: {},
};

export default React.memo(Wave);
