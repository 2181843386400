import React from 'react';

import ImgLogo from './assets/loggo.png';

function Logo() {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={ImgLogo} alt="" style={{ maxWidth: '100px', margin: '0 auto', display: 'block' }} />
      <div
        style={{
          fontFamily: 'var(--font-family-title)',
          fontWeight: 'bold',

          textAlign: 'center',
          transform: 'translateY(-6rem)',
        }}
      >
        <h4 style={{ fontSize: '3rem', fontWeight: 'bold', color: 'var(--primary-bg)' }}>D.A</h4>
      </div>
    </div>
  );
}

export default React.memo(Logo);
