import React from 'react';
import { bool, string } from 'prop-types';

import WithAnimation from '../WithAnimation';
import CopyRight from './CopyRight';
import EmbedLive from './EmbedLive';
import GiftCard from '../GiftCard';
import Wave from '../common/Wave';
import PhotoSection from '../PhotoSection/Loadable';
import WishesSection from '../WishesSection';

import WeddingLogo from '../common/WeddingLogo';

import { styWrapper } from './styles';

function FooterSection({ isInvitation, guestName }) {
  return (
    <div style={{ margin: '0', padding: '0', width: '100%' }}>
      <Wave style={{ marginTop: '-2px' }} />
      <EmbedLive />
      <Wave inverse style={{ marginTop: '-2px' }} />
      <PhotoSection />
      <GiftCard />
      <Wave inverse style={{ marginTop: '-2px' }} />
      <WishesSection />
      <div css={styWrapper} style={{ marginTop: '-4px' }}>
        <div className="secondary-bg" style={{ padding: '0 16px' }}>
          <div className="row padding__content">
            <div className="col-md-8 col-md-offset-2 text-center">
              <WithAnimation>
                <p>
                  {`Merupakan suatu kebahagiaan bagi kami apabila Bapak / Ibu / Saudara berkenan memberikan doa restu
                  secara langsung kepada kedua mempelai. Atas doa & perhatiannya, kami ucapkan terima kasih dan semoga
                  Allah selalu memberi perlindungan dan kesehatan bagi kita semua.`}
                </p>
              </WithAnimation>
              <WithAnimation delay={100}>
                <h2 className="main-font pr-co text__title" style={{ fontSize: '4rem' }}>
                  Terima Kasih
                </h2>
              </WithAnimation>
            </div>
          </div>
        </div>
        <hr className="border" />
        <WithAnimation>
          <WeddingLogo />
          <CopyRight />
        </WithAnimation>
      </div>
    </div>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
  guestName: string,
};

FooterSection.defaultProps = {
  guestName: '',
};

export default React.memo(FooterSection);
