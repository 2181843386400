import React from 'react';

import WithAnimationIntersect from '../WithAnimation/UseIntersect';
import Modal from './Modal';

import { styWrapper, styBackground } from './styles';

function GiftCard() {
  return (
    <div css={styBackground}>
      <div css={styWrapper}>
        <div className="row">
          <WithAnimationIntersect>
            <div className="text-wish text-center" style={{ padding: '16px', margin: '0 auto' }}>
              <h2 className="main-font pr-co text__title" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
                Amplop Digital
              </h2>
              <p className='pr-co' style={{ padding: '0 16px' }}>
                Bagi yang berhalangan hadir, <br /> kami hadirkan fitur dibawah ini:
                <br /> <br />
                <Modal />
              </p>
            </div>
          </WithAnimationIntersect>
        </div>
      </div>
    </div>
  );
}

export default GiftCard;
