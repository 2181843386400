import React from 'react';
import { node } from 'prop-types';
import Fade from 'react-reveal/Fade';

/**
 * component Adapter for React-reveal
 * See full documentation at https://www.react-reveal.com/examples/common/fade/
 * @param {React.ReactNode} children
 * @returns {React.ReactNode} wrapper with Fade Effect
 */
const WithAnimation = ({ children, ...rest }) => {
  return (
    <Fade bottom {...rest}>
      {children}
    </Fade>
  );
};

WithAnimation.propTypes = {
  children: node.isRequired,
};

export default WithAnimation;
