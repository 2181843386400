import { css } from '@emotion/core';
import Background from './assets/wave.svg';
import Logo from './assets/loggo.png';

export const styWave = (inverse) => css`
  background-image: url(${Background});
  background-size: cover;
  min-height: 12rem;
  background-color: var(--primary-bg);
  ${inverse && 'transform: rotate(180deg)'}
`;

export const styLogo = css`
  max-width: 100px;
  background-image: url(${Logo});
  height: 100px;
`;
