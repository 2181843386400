import jabat from './assets/i-jabattangan.jpg';
import sanitizer from './assets/i-handsanitizer.jpg';
import social from './assets/i-social.png';
import soap from './assets/i-cucitangan.jpg';
import freeTime from './assets/i-kerumunan.jpg';
import masker from './assets/i-masker.png';

export default [
  {
    icon: jabat,
    text: 'Hindari jabat tangan',
  },
  {
    icon: sanitizer,
    text: 'Gunakan hand sanitizer',
  },
  {
    icon: social,
    text: 'Social distancing',
  },
  {
    icon: soap,
    text: 'Rajin cuci tangan',
  },
  {
    icon: freeTime,
    text: 'Hindari kerumuman',
  },
  {
    icon: masker,
    text: 'Gunakan masker',
  },
];
