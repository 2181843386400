import { css } from '@emotion/core';

export const styBackground = css`
  background-color: var(--secondary-bg);
  background-size: cover;
  background-position: bottom;
`;

export const styWrapper = css`
  .text-wish {
    font-size: 16px;
  }

  .img {
    text-align: center;
    margin-bottom: 2em;
    padding: 0 32px;

    img {
      border-radius: 24px;
      width: 100%;
      max-width: 500px;
    }

    .img__ovo,
    .img__linkaja {
      display: inline-block;
      margin: 0 10px;
      border-radius: 16px;
      max-width: 200px;
      border: 2px solid var(--secondary-bg);

      @media screen and (max-width: 500px) {
        display: block;
        margin: 16px;
        margin: 16px auto;
      }
    }
  }
`;

export const styModal = css`
  .Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
  }
`;
