import { useLocation } from '@reach/router';
import getQueryValue from '../helpers/getQueryValue';

/**
 * custom hooks to return shift time type
 * @return {string}
 */
function useShiftTime() {
  const location = useLocation();
  const shiftType = getQueryValue(location, 'bank');

  return shiftType;
}

export default useShiftTime;
